import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { EMPTY, Observable, catchError, switchMap, tap } from 'rxjs';
import { User } from '@webcoffee/interfaces';
import { LocalStorageService } from './shared/utils/services/local-storage.service';
import { ToasterService } from './shared/utils/services/toastr.service';

interface AppState {
    errorCode: number | null;
    user: User | null;
    loading: boolean;
}

@Injectable({ providedIn: 'root' })
export class AppStore extends ComponentStore<AppState> {
    constructor(
        private http: HttpClient,
        private localStorage: LocalStorageService,
    ) {
        super({
            errorCode: null,
            user: null,
            loading: true,
        });
    }

    readonly getErrorCode = this.select((state) => state.errorCode);
    readonly getUser = this.select((state) => state.user);
    readonly getUserPermissions = this.select(this.getUser, (user) => {
        let permissions = new Set([]);

        if (user) {
            permissions = new Set([...user.role.permissions.map((p) => p.key), ...user.permissions.map((p) => p.key)]);
        }
        return permissions;
    });
    readonly getLoading = this.select((state) => state.loading);

    readonly pingEffect = this.effect(($) =>
        $.pipe(
            switchMap((_) =>
                this.http.get('/api/auth/status').pipe(
                    tap((user: User) => {
                        this.patchState({ user, errorCode: null });
                        this.localStorage.setData('user', user);

                        if (user) {
                            this.patchState({ loading: false });
                        }
                    }),
                    catchError((err) => {
                        this.patchState({ errorCode: err.status });

                        if (err.status === 401) {
                            this.rekeyEffect();
                        }
                        return EMPTY;
                    }),
                ),
            ),
        ),
    );

    readonly rekeyEffect = this.effect(($) =>
        $.pipe(
            switchMap((_) =>
                this.http.get('/api/auth/refresh').pipe(
                    tap((_) => this.pingEffect()),
                    catchError(() => {
                        this.patchState({ loading: false });
                        this.logoutEffect(false);
                        return EMPTY;
                    }),
                ),
            ),
        ),
    );

    readonly logoutEffect = this.effect((withCall$: Observable<boolean>) =>
        withCall$.pipe(
            tap(() => {
                this.patchState({ user: null });
                this.localStorage.removeData('user');
            }),
            switchMap((withCall) => {
                if (withCall) {
                    return this.http.post('/api/auth/logout', null);
                }
                return EMPTY;
            }),
        ),
    );
}
